"use client"

import { useEffect, useState } from "react"
import { sendGTMEvent } from "@next/third-parties/google"

import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { ReducedProductData } from "@/lib/storefront/product/product"
import {
  calculateCategoryPathString,
  calculateVariantString,
  determineEffectiveProductFromPdpContext,
  determineEffectiveProductId,
} from "@/lib/storefront/product/productHelper"

export interface PdpPageTrackingClientProps {
  pdpContext: PdpContext
}

export default function PdpPageTrackingClient(props: PdpPageTrackingClientProps) {
  const [tracked, setTracked] = useState(false)

  useEffect(() => {
    if (!tracked) {
      const productOrArticle = determineEffectiveProductFromPdpContext(props.pdpContext)
      const cardPrices: CardPrices = determineCardPrices(
        props.pdpContext.isB2B,
        props.pdpContext.prices,
        productOrArticle?.ecoTax
      )

      const pdpTrackingData: Object = getTrackingData(productOrArticle, cardPrices)

      console.log("Sending pdp page tracking event")
      sendGTMEvent(pdpTrackingData)
      setTracked(true)
    }
  }, [props.pdpContext, tracked])

  return <span className={"hidden"}>CategoryBrandsSectionTrackingClient</span>
}

function getTrackingData(productOrArticle: ReducedProductData, cardPrices: CardPrices): Object {
  const categoryPathString: string | undefined = calculateCategoryPathString(productOrArticle)

  return {
    event: "detailView",
    ecommerce: {
      currencyCode: productOrArticle.currency,
      promoView: {
        promotions: [] as any[],
      },
    },
    detail: {
      // should be within ecommerce!?
      actionField: {
        list: categoryPathString,
        action: "detail",
      },
      products: [
        {
          name: productOrArticle.title,
          id: determineEffectiveProductId(productOrArticle.id),
          price: cardPrices.salePrice,
          brand: productOrArticle.brand,
          variant: calculateVariantString(productOrArticle),
          category: categoryPathString,
        },
      ],
    },
  }
}

//TODO - Beipiel Artikel
/*
{
  "event": "detailView",
  "ecommerce": {
  "currencyCode": "EUR",
    "impressions": [],
    "promoView": {
    "promotions": []
  },
  "detail": {
    "actionField": {
      "list": "Büroeinrichtung/Schreibtische und Tische/elektrisch höhenverstellbare Schreibtische",
        "action": "detail"
    },
    "products": [
      {
        "name": "Topstar Elektrisch höhenverstellbarer Schreibtisch E-Table Smart mit T-Fußgestell",
        "id": "A254327",
        "price": "463.5",
        "brand": "Topstar",
        "variant": "1200 mm/600 mm/weiß/weiß/",
        "category": "Büroeinrichtung/Schreibtische und Tische/elektrisch höhenverstellbare Schreibtische"
      }
    ]
  }
},
  "gtm.uniqueEventId": 79
}*/
//TODO - Beispiel MasterData

/*
{
  "event": "detailView",
  "ecommerce": {
  "currencyCode": "EUR",
    "impressions": [],
    "promoView": {
    "promotions": []
  },
  "detail": {
    "actionField": {
      "list": "Büroeinrichtung/Schreibtische und Tische/elektrisch höhenverstellbare Schreibtische"
    },
    "products": [
      {
        "name": "Topstar Elektrisch höhenverstellbarer Schreibtisch E-Table Smart",
        "id": "E22495",
        "price": "463.5",
        "brand": "Topstar",
        "category": "Büroeinrichtung/Schreibtische und Tische/elektrisch höhenverstellbare Schreibtische"
      }
    ]
  }
},
  "gtm.uniqueEventId": 224
}*/

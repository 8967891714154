export const UB_OPTANON_ALERT_BOX_CLOSED_COOKIE_NAME = "OptanonAlertBoxClosed"

export const AUTH_COOKIE_NAME = "UB_AUTH"
export const BASKET_COOKIE_NAME = "sid"
export const LOGGED_IN_COOKIE_NAME = "UB_LOGGED_IN"
export const INTERSHOP_APITOKEN_COOKIE_NAME = "apiToken"
export const RECENTLY_VIEWED_PRODUCTS_COOKIE_NAME = "ubRecentlyViewedProducts"
export const RECENTLY_VIEWED_PRODUCTS_SEPARATOR = "|"

export const NEXT_HEADER_URL_KEY = "x-url-for-next"
export const NEXT_HEADER_SB_LOCALE = "x-sblocale-for-next" // see also next.config
export const NEXT_HEADER_ACCESS_TOKEN = "x-access-token-for-next"

import { SEARCH_PARAMS_FF_SEARCH_PARAMS } from "@/constants/ui"

import { getUbLocaleFromSbLocale } from "../locale-helper"
import { logger } from "../logger"
import { Category } from "../storefront/category/category"
import { Locales } from "./constants.cjs"
import { getSbLocale } from "./get-sb-locale"
import urlScheme from "./url-scheme.js"

export function getLanguageUriPrefix(destSbLocale?: string): string {
  const sbLocale = destSbLocale ?? getSbLocale()
  return sbLocale === "fr-ch" ? "/fr/CHF" : ""
}

export function baseUrl(trailingSlash = false, destSbLocale?: string): string {
  const baseUrl = process.env.NEXT_GENERATED_LINKS_BASE_URL ?? ""
  return baseUrl + getLanguageUriPrefix(destSbLocale) + (trailingSlash ? "/" : "")
}

export function getCanonicalCategoryLinkPathByUrlSlug(urlSlug: string, destSbLocale?: string): string {
  const catPrefix = isLocalDevelopment() ? "category/" : ""
  let link = `${baseUrl(false, destSbLocale)}/${catPrefix}${urlSlug}/`
  return link
}

export function isLocalDevelopment() {
  const nodeIsProductionMode = process.env.NODE_ENV === "production"
  const deployEnvIsEmptyOrDev = !process.env.DEPLOY_ENV || process.env.DEPLOY_ENV.startsWith("dev")
  const localDevelopment = !nodeIsProductionMode && deployEnvIsEmptyOrDev
  return localDevelopment
}

export function getCategoryLinkByUrlSlug(
  urlSlug: string,
  encryptedFfSearchParams?: string,
  params?: string,
  destSbLocale?: string
): string {
  let link = getCanonicalCategoryLinkPathByUrlSlug(urlSlug, destSbLocale)
  if (encryptedFfSearchParams) {
    link += `?${SEARCH_PARAMS_FF_SEARCH_PARAMS}=${encryptedFfSearchParams}`
  }
  if (params) {
    link += params
  }
  // logger.debug("getCategoryLinkByUrlSlug: " + link)
  return link
}

export function getCategoryLinkByName(categoryName: string, encryptedFfSearchParams?: string): string {
  return getCategoryLinkByUrlSlug(slugifyUB(categoryName), encryptedFfSearchParams)
}

export function getCanonicalBrandLinkPathByUrlSlug(urlSlug: string, destSbLocale?: string): string {
  if (!urlSlug) {
    logger.warn("getBrandLinkByUrlSlug: No urlSlug provided.")
  }
  let link = `${baseUrl(false, destSbLocale)}/brands/${urlSlug}`
  return link
}

export function getBrandLinkByUrlSlug(
  urlSlug: string,
  encryptedFfSearchParams?: string | undefined,
  destSbLocale?: string
): string {
  let link = getCanonicalBrandLinkPathByUrlSlug(urlSlug, destSbLocale)
  if (encryptedFfSearchParams) {
    link += `?${SEARCH_PARAMS_FF_SEARCH_PARAMS}=${encryptedFfSearchParams}`
  }
  // logger.debug("getBrandLinkByUrlSlug: " + link)
  return link
}

export function getBrandLinkByName(brandName: string): string {
  if (!brandName) {
    logger.warn("getBrandLinkByName: No urlSlug provided.")
    return baseUrl(true)
  }
  return getBrandLinkByUrlSlug(slugifyUB(brandName))
}

// returns undefined if brand does not exist
export async function getCheckedBrandLinkByName(brandName: string): Promise<string | undefined> {
  if (!brandName) {
    logger.warn("getCheckedBrandLinkByName: No urlSlug provided.")
    return undefined
  }
  // needs "server-only"
  // if (!(await brandPageExists(brandName))) {
  //   return undefined
  // }
  // => very bad workaround (+redirect in brands/page):
  if (brandName.toLowerCase() === "udobär") {
    return undefined
  }
  return getBrandLinkByUrlSlug(slugifyUB(brandName))
}

export function getSearchLink(query: string, encryptedFfSearchParams?: string): string {
  let link = `${baseUrl()}/search/${encodeURIComponent(query)}/`
  if (encryptedFfSearchParams) {
    link += `?${SEARCH_PARAMS_FF_SEARCH_PARAMS}=${encryptedFfSearchParams}`
  }
  return link
}

export function getLinkWithEncryptedFfSearchParams(
  query?: string,
  category?: Category,
  brandSlug?: string,
  encryptedFfSearchParams?: string
): string {
  if (query) {
    return getSearchLink(query, encryptedFfSearchParams)
  } else if (category) {
    return getCategoryLinkByUrlSlug(category.urlSlug, encryptedFfSearchParams)
  } else if (brandSlug) {
    // order matters: must be considered only if there is no category
    return getBrandLinkByUrlSlug(encodeURIComponent(brandSlug), encryptedFfSearchParams)
  } else {
    logger.warn("getLinkWithEncryptedFfSearchParams: No query and no base category provided.")
    return ""
  }
}

export function getResetLinkToSearchResultOrCategory(query?: string, category?: Category, brandSlug?: any): string {
  if (query) {
    return getSearchLink(query)
  } else if (category) {
    return getCategoryLinkByUrlSlug(category.urlSlug)
  } else if (brandSlug) {
    // order matters: must be considered only if there is no category
    return getBrandLinkByUrlSlug(encodeURIComponent(brandSlug))
  } else {
    logger.warn("getLinkToSearchResultOrCategory: No query, no base category and no brandSlug provided.")
    return ""
  }
}

export function getCanonicalStoryLink(storySlug: string, destSbLocale?: string): string {
  const canonicalSlug = storySlug === "home" ? "" : storySlug
  if (urlScheme.storiesRedirectedToInfo.includes(canonicalSlug)) {
    return getStoryLink(storySlug, destSbLocale)
  }
  return `${baseUrl(false, destSbLocale)}/${canonicalSlug}`
}

export function getStoryLink(storySlug: string, destSbLocale?: string): string {
  if (storySlug === "home") {
    return baseUrl(true, destSbLocale)
  } else {
    return `${baseUrl(false, destSbLocale)}/info/${getUbLocaleFromSbLocale(destSbLocale)}/${storySlug}`
  }
}

//SEE: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/normalize
export function slugifyUB(input: string): string {
  if (input === null || input === undefined) {
    return ""
  }

  // lower case
  let result: string = input.toLowerCase()

  // now convert lowercase umlauts and szlig
  result = result
    .replace(/ä/g, "ae")
    .replace(/ö/g, "oe")
    .replace(/ü/g, "ue")
    .replace(/ß/g, "ss")
    // convert whitespaces and plus signs to minus
    .replace(/ /g, "-")
    .replace(/\+/g, "-")

  // strip accents
  result = result.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

  // replace everything that is not a letter or number
  result = result.replace(/[^a-zA-Z0-9\-]/g, "")

  // remove trailing dashes
  result = result.replace(/^\-+/, "")
  result = result.replace(/\-+$/, "")

  // replace multiple consecutive dashes with a single dash
  result = result.replace(/\-+/g, "-")

  return result
}

/* TODO - need to check if the above code is really correct
Legacy JAVA Code Found in UBRewriteHelper:

  public static String slugify(String input) {
    if (input == null) {
      return "";
    }
    // lower case
    String result = input.toLowerCase();

    // now convert lowercase umlauts and szlig
    result = result
        .replaceAll("\u00e4", "ae")
        .replaceAll("\u00f6", "oe")
        .replaceAll("\u00fc", "ue")
        .replaceAll("\u00df", "ss")
    // convert whitespaces and plus signs to minus
        .replaceAll(" ", "-")
        .replaceAll("\\+", "-");

    // replaces characters with accents used in French or Czech language
    result = StringUtils.stripAccents(result);

    // replace everything that is not a letter
    result = result.replaceAll("[^a-zA-Z0-9\\-]", "");

    // remove trailing dashes
    result = result.replaceAll("^[\\-]{1,}", "");
    result = result.replaceAll("[\\-]{1,}$", "");

    // replace multiple consecutive dashes with a single dash
    result = result.replaceAll("[\\-]{2,}", "-");
    return result;
  }

}
*/

export function appendQueryParameter(url: string, keyToAppend: string, valueToAppend: string): string {
  const dummyBase = "http://dummy"

  let urlObj
  if (url.startsWith("http://") || url.startsWith("https://")) {
    urlObj = new URL(url)
  } else {
    urlObj = new URL(url, dummyBase)
  }

  urlObj.searchParams.set(keyToAppend, valueToAppend)

  if (urlObj.origin === dummyBase) {
    return urlObj.pathname + urlObj.search
  }

  return urlObj.toString()
}

// returns e.g. "www.test." from "www.test.udobaer.de"
export function getSubdomain(destDomain: string | undefined): string {
  if (!destDomain) {
    return ""
  }
  if (destDomain === "localhost") {
    return ""
  }
  const parts = destDomain.split(".")
  return parts.slice(0, -2).join(".") + "."
}

// returns e.g. "https://www.test.udobaer.de"
export function getBaseUrlBySbLocale(destSbLocale: string, requestUrl: URL | null) {
  const destDomain: string = Locales.sbLocaleToDomain[destSbLocale]
  const destSubDomain = getSubdomain(requestUrl?.hostname)
  const scheme = requestUrl?.protocol || "https:" // protocol includes ":"
  const destBaseUrl = `${scheme}//${destSubDomain}${destDomain}` + (requestUrl?.port ? ":" + requestUrl.port : "")
  return destBaseUrl
}

export function generateProductLink(
  name?: string,
  sku?: string,
  searchParams?: URLSearchParams,
  destSbLocale?: string
): string {
  //e.g. https://www.udobaer.de/meta-selbstbauregal-fachboeden-und-profile-e13510.html?breadcrumb=%2FLagerbedarf%2FRegale%2FFachbodenregale&TrackingPos=13
  const productSlug: string = slugifyUB(name ?? "")
  const effectiveSku: string = sku ?? ""
  const sp = searchParams && searchParams?.size > 0 ? "?" + searchParams?.toString() : ""
  const link =
    baseUrl(true, destSbLocale) +
    (isLocalDevelopment() ? "product/" : "") +
    (productSlug + "-" + effectiveSku + ".html").toLowerCase() +
    sp

  //logger.debug("Generated PDP Link for sku " + effectiveSku + " : " + baseUrl(true, destSbLocale) + " : " + link)

  return link
}

export function extractProductIdFromSlug(slug: string): string {
  const htmlSuffix = ".html"
  const uniqueMarker = "-"
  const pattern = new RegExp(`[^/]+${uniqueMarker}([0-9eE]{6}-[0-9]{4}|[0-9eE]{6})${htmlSuffix}$`)
  // e.g. "udobaer-muelltonne-citybac-classic-aus-recyceltem-material-120-l-273073.html"
  const match = slug.match(pattern)

  if (match && match[1]) {
    // logger.info(`Product ID FOUND in "${slug}" by regex "${pattern}"`)
    return match[1]
  }

  logger.info(`Product ID not found in "${slug}" by regex "${pattern}". Trying this as productId.`)
  return slug
}

"use client"

import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react"
import Image from "next/image"
import { useUbPdpState } from "@/store/UbpdpState"
import { ImageRO } from "@ub/product-client"
import { CircleChevronLeft, CircleChevronRight, XCircleIcon } from "lucide-react"

import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { determineEffectiveProductFromPdpContext } from "@/lib/storefront/product/productHelper"
import { cn } from "@/lib/utils"
import {
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/components"
import ProductCardDiscount from "@/components/product/ProductCardDiscount"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"

interface GalleryImageProps {
  id: string
  active: boolean
}

function getImageByTypeAndViewID(images: ImageRO[] | undefined, typeID: string, viewID?: string): ImageRO | undefined {
  return images?.find((image) => image.typeID === typeID && (!viewID || image.viewID === viewID))
}

function transformImages(images: ImageRO[] | undefined): string[] {
  if (!images) {
    return []
  }

  const primaryImage = images.find((image) => image.primaryImage && image.typeID === "S")

  const otherImages = images
    .filter((image) => image.typeID === "S" && !image.primaryImage)
    .map((image) => image.viewID || "")
    .filter((viewID) => viewID !== "")

  if (primaryImage) {
    return [primaryImage.viewID || "", ...otherImages]
  }

  return otherImages
}

type ProductImageContextType = {
  viewID: string
  image_gallery: ImageRO[]
  setViewID: Dispatch<SetStateAction<string>>
  imageZoomed: boolean
  setImageZoomed: Dispatch<SetStateAction<boolean>>
}

const defaultContext = {
  viewID: "",
  image_gallery: [],
  setViewID: (id: string) => undefined,
  imageZoomed: false,
  setImageZoomed: (zoomed: boolean) => undefined,
}

const ProductImageContext = createContext<ProductImageContextType>(defaultContext)

const GalleryImage: FC<GalleryImageProps> = ({ id, active }) => {
  const { setViewID, image_gallery } = useContext(ProductImageContext)
  const image = getImageByTypeAndViewID(image_gallery, "S", id)
  const src = image?.effectiveUrl || ""
  const width = image?.imageActualWidth || 100
  const height = image?.imageActualHeight || 100
  const alt = image?.name || ""

  return (
    <button
      className={cn("max-h-16 w-full cursor-pointer rounded-sm border bg-white", {
        "border-primary": active,
      })}
      onClick={() => setViewID(id)}
    >
      <Image className="size-full rounded-sm object-contain" src={src} alt={alt} width={width} height={height} />
    </button>
  )
}

const PreviewImage = (props: { viewID: string; pdpContext: PdpContext }) => {
  const { image_gallery } = useContext(ProductImageContext)
  const { imageZoomed, setImageZoomed } = useContext(ProductImageContext)

  const preview_image = getImageByTypeAndViewID(image_gallery, "L", props.viewID)
  const zoom_image = getImageByTypeAndViewID(image_gallery, "ZOOM", props.viewID)

  const articleOrMasterProduct = determineEffectiveProductFromPdpContext(props.pdpContext)
  const { buyboxQuantity } = useUbPdpState()

  const brandLogoUrl = articleOrMasterProduct?.brandLogoUrl

  function toggleZoomState() {
    setImageZoomed(!imageZoomed)
  }

  return (
    <div className="group relative flex w-full justify-center rounded-sm border bg-white">
      {props?.pdpContext?.productAndArticle?.article && props?.pdpContext?.prices && (
        <div
          className={cn("block", {
            hidden: imageZoomed,
          })}
        >
          <ProductCardDiscount
            product={props.pdpContext.productAndArticle.article}
            prices={props.pdpContext.prices}
            isB2BCustomer={props.pdpContext.isB2B}
            quantity={buyboxQuantity}
            legacyPrices={false}
          />
        </div>
      )}

      {brandLogoUrl && brandLogoUrl !== "" && (
        <img
          className={cn("absolute right-7 top-7 max-h-24 max-w-24 object-cover", {
            "right-7": !imageZoomed,
            "left-7": imageZoomed,
          })}
          src={brandLogoUrl}
          alt="BRAND"
        />
      )}

      <div className="">
        <div className="flex h-full flex-col items-center justify-center py-6">
          <ImageWithFallback
            onClick={toggleZoomState}
            src={(!imageZoomed ? preview_image?.effectiveUrl : zoom_image?.effectiveUrl) || ""}
            alt={articleOrMasterProduct.title}
            width={!imageZoomed ? preview_image?.imageActualWidth : zoom_image?.imageActualWidth}
            height={!imageZoomed ? preview_image?.imageActualHeight : zoom_image?.imageActualHeight}
            priority={true}
            className={cn("h-72 cursor-zoom-in object-contain sm:h-96", {
              "h-[90svh] sm:h-[90svh] cursor-zoom-out": imageZoomed && image_gallery.length < 2,
              "h-[80svh] sm:h-[80svh] cursor-zoom-out": imageZoomed && image_gallery.length >= 2,
            })}
          />
        </div>
      </div>
    </div>
  )
}

interface ProductDetailModalImageGalleryProps {
  pdpContext: PdpContext
  onImageZoomed?: (isZoomed: boolean) => void
}

function ProductDetailModalImageGallery({ pdpContext, onImageZoomed }: ProductDetailModalImageGalleryProps) {
  const productOrArticle = determineEffectiveProductFromPdpContext(pdpContext)

  const primaryImage = productOrArticle?.image_gallery
    ? productOrArticle.image_gallery.find((image) => image.primaryImage && image.typeID === "S")
    : undefined

  const [viewID, setViewID] = useState<string>(primaryImage?.viewID || "")
  const [imageZoomed, setImageZoomed] = useState<boolean>(false)

  const [galleryApi, setGalleryApi] = useState<CarouselApi>()
  const [previewApi, setPreviewApi] = useState<CarouselApi>()
  const [activeIndex, setActiveIndex] = useState<number>(0)

  const gallery_image_ids = transformImages(productOrArticle?.image_gallery)
  const context = {
    viewID,
    image_gallery: productOrArticle?.image_gallery ?? [],
    setViewID,
    imageZoomed,
    setImageZoomed,
  }

  const containerRef = useRef<HTMLDivElement>(null)

  const handleThumbsClick = (index: number) => {
    if (!galleryApi || !previewApi) {
      return
    } else {
      setActiveIndex(index)
      galleryApi.scrollTo(index)
      previewApi.scrollTo(index)
    }
  }

  const handlePreviewClick = (index: number) => {
    setImageZoomed(!imageZoomed)
  }

  const onSelect = useCallback(() => {
    if (!previewApi || !galleryApi) return
    setActiveIndex(previewApi.selectedScrollSnap())
    galleryApi.scrollTo(previewApi.selectedScrollSnap())
  }, [previewApi, galleryApi, setActiveIndex])

  useEffect(() => {
    if (!previewApi) {
      return
    }
    previewApi.on("select", onSelect)
  }, [onSelect, previewApi])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!imageZoomed) {
        return
      }
      if (!previewApi) {
        return
      }

      if (event.key === "ArrowLeft") {
        previewApi.scrollPrev()
      } else if (event.key === "ArrowRight") {
        previewApi.scrollNext()
      } else if (event.key === "Escape") {
        setImageZoomed(false)
      }
    }

    if (imageZoomed) {
      containerRef.current?.focus()
      window.addEventListener("keydown", handleKeyDown)
    } else {
      window.removeEventListener("keydown", handleKeyDown)
    }

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
    }
  }, [imageZoomed, previewApi, setImageZoomed])

  useEffect(() => {
    onImageZoomed && onImageZoomed(imageZoomed)
  }, [imageZoomed])

  const enforceOnlyJsFocus = -1
  return (
    <>
      <ProductImageContext.Provider value={context}>
        <div
          ref={containerRef}
          tabIndex={enforceOnlyJsFocus}
          className={cn("block", {
            "fixed z-modal-level top-0 left-0 bottom-0 right-0 bg-gray-50": imageZoomed,
          })}
        >
          <div className={"relative flex flex-col gap-2"}>
            <Carousel
              setApi={setPreviewApi}
              opts={{ loop: true, containScroll: "keepSnaps", dragFree: false }}
              className="w-full max-w-full p-0"
            >
              <button
                className={cn("group absolute top-1/2 hidden -translate-y-1/2 bg-transparent", {
                  "z-above-all left-0 w-24 flex justify-center items-center":
                    imageZoomed && gallery_image_ids.length > 1,
                })}
                onClick={() => previewApi?.scrollPrev()}
              >
                <CircleChevronLeft className={"size-8 bg-transparent group-hover:text-primary"} />
              </button>

              <button
                className={cn("group absolute top-1/2 hidden -translate-y-1/2 bg-transparent", {
                  "z-above-all right-0 w-24 flex justify-center items-center":
                    imageZoomed && gallery_image_ids.length > 1,
                })}
                onClick={() => previewApi?.scrollNext()}
              >
                <CircleChevronRight className={"size-8 bg-transparent group-hover:text-primary"} />
              </button>

              <button
                className={cn("group absolute top-8 hidden size-24 -translate-y-1/2 bg-transparent", {
                  "flex items-center justify-center z-above-all right-0": imageZoomed,
                })}
                onClick={() => setImageZoomed(false)}
              >
                <XCircleIcon className={"size-8 bg-white group-hover:text-primary"} />
              </button>

              <CarouselContent className={"p-0"}>
                {gallery_image_ids.map((id, index) => (
                  <CarouselItem onClick={() => handlePreviewClick(index)} key={index} className={"h-4/5 w-full"}>
                    <PreviewImage pdpContext={pdpContext} viewID={id} />
                  </CarouselItem>
                ))}
              </CarouselContent>
            </Carousel>

            <div
              className={cn("px-12", {
                hidden: gallery_image_ids.length < 2,
              })}
            >
              <Carousel
                setApi={setGalleryApi}
                opts={{ loop: true, containScroll: "keepSnaps", dragFree: true }}
                className="w-full max-w-full "
              >
                <CarouselContent>
                  {gallery_image_ids.map((id, index) => (
                    <CarouselItem
                      onClick={() => handleThumbsClick(index)}
                      key={index}
                      className={"basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-1/5 xl:basis-1/6"}
                    >
                      <GalleryImage id={id} active={activeIndex === index} />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious className={"-translate-x-12"} bannermode={false} fullwidth={false} />
                <CarouselNext className={"translate-x-12"} bannermode={false} fullwidth={false} />
              </Carousel>
            </div>
          </div>
        </div>
      </ProductImageContext.Provider>
    </>
  )
}

export default ProductDetailModalImageGallery

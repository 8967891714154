import { useState } from "react"
import { CircleUserIcon } from "lucide-react"
import { useTranslations } from "next-intl"

import { UserData } from "@/lib/intershop/interfaces/user/user"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/components"
import { UbHeaderStoryblok } from "@/components/storyblok/component-types"
import UbLoginForm from "@/components/storyblok/UbLoginForm"

import StoryblokAccountForm from "./StoryblokAccountForm"

interface StoryblokAccountPopupClientProps {
  blok: UbHeaderStoryblok
  userData?: UserData
}

export default function StoryblokAccountPopupClient({ blok, userData }: StoryblokAccountPopupClientProps) {
  const [popUpOpen, setPopUpOpen] = useState(false)
  const t = useTranslations("storefront")

  return (
    <div className="flex h-full rounded">
      <>
        {userData ? (
          <Popover open={popUpOpen} onOpenChange={setPopUpOpen}>
            <PopoverTrigger>
              <div className="flex flex-row items-center justify-start gap-2 font-semibold text-gray-600 xs:min-w-10 lg:min-w-36">
                <CircleUserIcon className={"h-[20px] text-black"} />
                <p className="hidden text-left leading-tight tracking-tight lg:block">
                  {userData.firstName + " " + userData.lastName}
                </p>
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto rounded-none border-black p-6" align={"end"} alignOffset={-80}>
              <div className="w-[260px]">
                <StoryblokAccountForm blok={blok} userData={userData} />
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <Popover open={popUpOpen} onOpenChange={setPopUpOpen}>
            <PopoverTrigger aria-label={t("account.nav.menu")}>
              <div className="flex flex-row items-center justify-start gap-2 xs:min-w-10 lg:min-w-36">
                <CircleUserIcon className={"h-[20px] text-black"} />
                <p className="hidden whitespace-nowrap lg:block">{t("account.nav.menu")}</p>
              </div>
            </PopoverTrigger>
            <PopoverContent className="w-auto overflow-visible rounded p-6 lg:mr-20" align={"end"} alignOffset={-80}>
              <div className="w-[260px] lg:w-[570px]">
                <UbLoginForm blok={blok} />
              </div>
            </PopoverContent>
          </Popover>
        )}
      </>
    </div>
  )
}

"use client"
import {AccountContext} from "@/lib/storefront/account/account-service"
import React from "react";
import AccountSignUpEmailAndPassword from "@/components/account/signup/AccountSignUpEmailAndPassword";
import AccountSignUpB2BContact from "@/components/account/signup/AccountSignUpB2BContact";
import AccountSignUpAddress from "@/components/account/signup/AccountSignUpAddress";
import AccountSignUpLegal from "@/components/account/signup/AccountSignUpLegal";

interface AccountSignUpB2BProps {
  accountContext: AccountContext
}

export default function AccountSignUpB2B(props: AccountSignUpB2BProps) {


  return (

    <section className={"relative block bg-cover "}>
      <div className={"mx-auto w-full max-w-7xl justify-center"}>
        <div className={"flex w-full flex-col items-start justify-start gap-2 pt-4"}>
          <div className={"w-full grow bg-ub-background"}>Geschäftskunde</div>
          <AccountSignUpEmailAndPassword accountContext={props.accountContext}/>
            <AccountSignUpB2BContact accountContext={props.accountContext}/>
          <AccountSignUpAddress accountContext={props.accountContext}/>
          <AccountSignUpLegal accountContext={props.accountContext}/>
        </div>
      </div>
    </section>

  )
}

"use client"

import { useMemo } from "react"
import { useUbPdpState } from "@/store/UbpdpState"
import { generalVerify } from "jose"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { CardPrices, determineCardPrices, generateBasePriceInfo } from "@/lib/price-utils"
import { PdpContext } from "@/lib/storefront/product/pdp-service"
import { determineEffectiveProductFromPdpContext, showProductPrice } from "@/lib/storefront/product/productHelper"

export default function ProductDetailBuyboxPrice(props: {
  pdpContext: PdpContext
  showEcoTaxFlag: boolean | undefined
}) {
  const t = useTranslations("storefront")
  const productOrArticle = determineEffectiveProductFromPdpContext(props.pdpContext)
  const ecoTax: number | undefined = productOrArticle?.ecoTax
  const ecoTaxFormatted = formatPrice(ecoTax, productOrArticle?.currency)
  const minOrderQuantity = productOrArticle?.minOrderQuantity
  const stepOrderQuantity = productOrArticle?.stepOrderQuantity
  const showEcoTax = !!props.showEcoTaxFlag && getCountry() === "fr" && ecoTax !== undefined && ecoTax > 0
  const packagingUnitInfo = productOrArticle?.packagingUnitInfo ?? ""
  const minOrderInfo = productOrArticle?.minOrderInfo ?? ""
  const stepOrderInfo = productOrArticle?.stepOrderInfo ?? ""

  const minOrderQuantityVisible = minOrderQuantity && minOrderQuantity > 1 && minOrderInfo !== ""
  const stepOrderQuantityVisible = stepOrderQuantity && stepOrderQuantity > 1 && stepOrderInfo !== ""

  const { buyboxQuantity } = useUbPdpState()

  const cardPrices = useMemo(() => {
    return determineCardPrices(
      props.pdpContext.isB2B,
      props.pdpContext.prices,
      productOrArticle?.ecoTax,
      buyboxQuantity,
      productOrArticle?.id
    )
  }, [props.pdpContext.isB2B, props.pdpContext.prices, productOrArticle?.ecoTax, buyboxQuantity])

  const showPrice = useMemo(() => {
    const { showPrice } = showProductPrice(productOrArticle, cardPrices)
    return showPrice
  }, [productOrArticle, cardPrices])

  const isReducedPrice = useMemo(() => {
    return cardPrices.listPrice > cardPrices.salePrice
  }, [cardPrices])

  const pricePerUnitText = useMemo(() => {
    return generateBasePriceInfo(productOrArticle, cardPrices)
  }, [productOrArticle, cardPrices])

  return (
    <div className={"flex flex-col items-start justify-start"}>
      <pre className={"fixed inset-0 z-modal-level hidden"}>{JSON.stringify(props.pdpContext.prices)}</pre>

      <pre className={"fixed inset-0 z-modal-level hidden"}>{JSON.stringify(cardPrices)}</pre>

      {showPrice ? (
        <div>
          {isReducedPrice ? (
            props.pdpContext.isB2B ? (
              <div className="flex flex-row items-baseline justify-start gap-1">
                {!cardPrices.isSinglePrice && (
                  /*B2B "ab" Listenpreis*/
                  <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
                )}
                {cardPrices.listPriceFormatted !== cardPrices.salePriceFormatted && (
                  /*B2B Streich Listenpreis*/
                  <p className="text-sm font-bold text-gray-500 line-through">{cardPrices.listPriceFormatted}</p>
                )}
              </div>
            ) : (
              /*B2C "Aktionspreis"*/
              <p className={"text-nowrap text-xs font-bold text-primary"}>{t("basket.add_dialog.special_offer")}</p>
            )
          ) : (
            props.pdpContext.isB2B && (
              /*B2B Strike-Price now hidden*/
              <div className="flex flex-row items-baseline justify-start gap-1">
                <p className="hidden text-sm font-bold line-through">-</p>
              </div>
            )
          )}

          <div className="flex gap-2">
            <div className="flex flex-row items-baseline justify-start gap-1">
              {!cardPrices.isSinglePrice && (
                <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
              )}
              <p className="text-xl font-bold text-primary">
                {/*"salePrice "*/}

                {cardPrices.salePriceFormatted}
                {/*{isB2BCustomer ? "*" : ""}*/}
              </p>
            </div>
            {packagingUnitInfo !== "" && (
              <p
                /* Preis pro Paar (Paar = 2 Stück) */
                className="pt-1 text-xs text-gray2"
              >
                {packagingUnitInfo}
              </p>
            )}
          </div>

          {showEcoTax && (
            /*"showEcoTax in FR "*/
            <p className="text-nowrap pt-1 text-xs text-gray2">{t("product.eco_tax", { ecoTax: ecoTaxFormatted })}</p>
          )}

          {(pricePerUnitText !== "" || minOrderQuantityVisible || stepOrderQuantityVisible) && (
            <>
              <div className="mt-2 flex flex-row gap-2">
                {pricePerUnitText !== "" && (
                  /*pricePerUnitText 17,33€ pro 1 LTR "*/
                  <p
                    className="text-nowrap text-xs text-gray-400"
                    dangerouslySetInnerHTML={{ __html: pricePerUnitText }}
                  />
                )}
                {minOrderQuantityVisible && (
                  <p className="text-nowrap text-xs text-gray-400">
                    {/*Mindestbestellmenge: 10 Stück "*/}
                    {minOrderInfo}
                  </p>
                )}
              </div>
              <div>
                {stepOrderQuantityVisible && (
                  <p className="text-nowrap text-xs text-gray-400">
                    {/*Mengeninterval: 2 "*/}
                    {stepOrderInfo}
                  </p>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div>
          {/* Preis auf Anfrage */}
          <p className={"text-nowrap text-xl font-bold text-primary"}>{t("product.ask_price")}</p>
        </div>
      )}
    </div>
  )
}

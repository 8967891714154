"use client"

import * as React from "react"
import { ChevronLeftIcon, ChevronRightIcon } from "@radix-ui/react-icons"
import Autoplay from "embla-carousel-autoplay"
import useEmblaCarousel, { type UseEmblaCarouselType } from "embla-carousel-react"

import { cn } from "../helpers/utils"

type CarouselApi = UseEmblaCarouselType[1]
type UseCarouselParameters = Parameters<typeof useEmblaCarousel>
type CarouselOptions = UseCarouselParameters[0]
type CarouselPlugin = UseCarouselParameters[1]

type CarouselProps = {
  opts?: CarouselOptions
  plugins?: CarouselPlugin
  orientation?: "horizontal" | "vertical"
  duration?: number
  autoplay?: boolean
  setApi?: (api: CarouselApi) => void
}

type CarouselContextProps = {
  carouselRef: ReturnType<typeof useEmblaCarousel>[0]
  api: ReturnType<typeof useEmblaCarousel>[1]
  scrollPrev: () => void
  scrollNext: () => void
  canScrollPrev: boolean
  canScrollNext: boolean
  selectedIndex: number
  scrollTo: (index: number) => void
} & CarouselProps

const CarouselContext = React.createContext<CarouselContextProps | null>(null)

function useCarousel() {
  const context = React.useContext(CarouselContext)

  if (!context) {
    throw new Error("useCarousel must be used within a <Carousel />")
  }

  return context
}

const Carousel = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselProps>(
  (
    {
      orientation = "horizontal",
      duration = 2000,
      autoplay = false,
      opts,
      setApi,
      plugins,
      className,
      children,
      ...props
    },
    ref
  ) => {
    const autoPlayPlugin = autoplay ? [Autoplay({ delay: duration })] : []
    const plugin = plugins ? [...plugins, ...autoPlayPlugin] : autoPlayPlugin

    const [carouselRef, api] = useEmblaCarousel(
      {
        ...opts,
        axis: orientation === "horizontal" ? "x" : "y",
      },
      plugin
    )
    const [canScrollPrev, setCanScrollPrev] = React.useState(false)
    const [canScrollNext, setCanScrollNext] = React.useState(false)
    const [selectedIndex, setSelectedIndex] = React.useState(0)

    const onSelect = React.useCallback((api: CarouselApi) => {
      if (!api) {
        return
      }

      setCanScrollPrev(api.canScrollPrev())
      setCanScrollNext(api.canScrollNext())
      setSelectedIndex(api.selectedScrollSnap())
    }, [])

    const scrollPrev = React.useCallback(() => {
      api?.scrollPrev()
    }, [api])

    const scrollNext = React.useCallback(() => {
      api?.scrollNext()
    }, [api])

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "ArrowLeft") {
          event.preventDefault()
          scrollPrev()
        } else if (event.key === "ArrowRight") {
          event.preventDefault()
          scrollNext()
        }
      },
      [scrollPrev, scrollNext]
    )

    const scrollTo = React.useCallback(
      (index: number) => {
        api?.scrollTo(index)
      },
      [api]
    )

    React.useEffect(() => {
      if (!api || !setApi) {
        return
      }

      setApi(api)
    }, [api, setApi])

    React.useEffect(() => {
      if (!api) {
        return
      }

      onSelect(api)
      api.on("reInit", onSelect)
      api.on("select", onSelect)

      return () => {
        api?.off("select", onSelect)
      }
    }, [api, onSelect])

    return (
      <CarouselContext.Provider
        value={{
          carouselRef,
          api,
          opts,
          orientation: orientation || (opts?.axis === "y" ? "vertical" : "horizontal"),
          scrollPrev,
          scrollNext,
          canScrollPrev,
          canScrollNext,
          selectedIndex,
          scrollTo,
        }}
      >
        <div
          ref={ref}
          onKeyDownCapture={handleKeyDown}
          className={cn("relative", className)}
          role="region"
          aria-roledescription="carousel"
          {...props}
        >
          {children}
        </div>
      </CarouselContext.Provider>
    )
  }
)
Carousel.displayName = "Carousel"

const CarouselContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { carouselRef, orientation } = useCarousel()

    return (
      <div ref={carouselRef} className="overflow-hidden">
        <div
          ref={ref}
          className={cn("flex", orientation === "horizontal" ? "-ml-4" : "-mt-4 flex-col", className)}
          {...props}
        />
      </div>
    )
  }
)
CarouselContent.displayName = "CarouselContent"

const CarouselItem = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    const { orientation } = useCarousel()

    return (
      <div
        ref={ref}
        role="group"
        aria-roledescription="slide"
        className={cn(
          "flex min-w-0 shrink-0 grow-0 basis-full justify-center",
          orientation === "horizontal" ? "pl-4" : "pt-4",
          className
        )}
        {...props}
      />
    )
  }
)
CarouselItem.displayName = "CarouselItem"

type CarouselDotsProps = {
  bannermode: boolean
  fullwidth: boolean
}

const CarouselDots = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement> & CarouselDotsProps>(
  ({ className, bannermode, fullwidth, ...props }, ref) => {
    const { selectedIndex, scrollTo, api, canScrollNext, canScrollPrev } = useCarousel()

    return (
      <div
        ref={ref}
        className={cn("relative mt-4 flex w-full flex-wrap items-center justify-center gap-2", className, {
          hidden: !canScrollNext && !canScrollPrev,

          "justify-start pl-4 mt-0": bannermode,
        })}
        {...props}
      >
        {api
          ?.scrollSnapList()
          .map((_, index) => (
            <button
              aria-label={`slide ${index + 1}`}
              key={index}
              className={cn("size-4 rounded-full", index === selectedIndex ? "bg-primary" : "bg-black")}
              onClick={() => scrollTo(index)}
            />
          ))}
      </div>
    )
  }
)
CarouselDots.displayName = "CarouselDots"

type CarouselArrowProps = {
  bannermode: boolean
  fullwidth: boolean
}

const CarouselPrevious = React.forwardRef<
  HTMLButtonElement,
  React.HTMLAttributes<HTMLButtonElement> & CarouselArrowProps
>(({ className, bannermode, fullwidth, ...props }, ref) => {
  const { orientation, scrollPrev, canScrollPrev } = useCarousel()

  return (
    <button
      ref={ref}
      className={cn(
        "absolute rounded-full",
        {
          "left-[-0.05rem] top-1/2 -translate-y-1/2 sm:left-[-0.15rem]":
            orientation === "horizontal" && !fullwidth && !bannermode,
          "left-[-1.15rem] top-1/2 -translate-y-1/2 sm:left-[-1.15rem]":
            orientation === "horizontal" && fullwidth && !bannermode,
          "-top-12 left-1/2 -translate-x-1/2 rotate-90": orientation === "vertical",
          "right-16 -bottom-12 z-20": bannermode,
        },
        className,
        {
          hidden: !canScrollPrev,
        }
      )}
      onClick={scrollPrev}
      {...props}
    >
      <ChevronLeftIcon className={"size-8"} />
      <span className="sr-only">Previous slide</span>
    </button>
  )
})
CarouselPrevious.displayName = "CarouselPrevious"

const CarouselNext = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement> & CarouselArrowProps>(
  ({ className, bannermode, fullwidth, ...props }, ref) => {
    const { orientation, scrollNext, canScrollNext } = useCarousel()

    return (
      <button
        ref={ref}
        className={cn(
          "absolute rounded-full",
          {
            "right-[-0.05rem]  top-1/2 -translate-y-1/2 sm:right-[-0.15rem]":
              orientation === "horizontal" && !fullwidth && !bannermode,
            "right-[-1.15rem]  top-1/2 -translate-y-1/2 sm:right-[-1.15rem]":
              orientation === "horizontal" && fullwidth && !bannermode,
            "-bottom-12 left-1/2 -translate-x-1/2 rotate-90": orientation === "vertical",
            "right-0 -bottom-12 z-20": bannermode,
          },
          className,
          {
            hidden: !canScrollNext,
          }
        )}
        onClick={scrollNext}
        {...props}
      >
        <ChevronRightIcon className={"size-8"} />
        <span className="sr-only">Next slide</span>
      </button>
    )
  }
)
CarouselNext.displayName = "CarouselNext"

export { Carousel, CarouselContent, CarouselDots, CarouselItem, CarouselNext, CarouselPrevious, type CarouselApi }

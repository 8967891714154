import { useMemo } from "react"
import { ProductPriceContainerCtnrROV1 } from "@ub/pricing-client"
import { useTranslations } from "next-intl"

import { determineCardPrices } from "@/lib/price-utils"
import { ReducedProductData } from "@/lib/storefront/product/product"
import { cn } from "@/lib/utils"

const SHOW_ADDITIONAL_BADGES = true

function determineSpecialFlags(product: ReducedProductData) {
  let isOwnBrand = false
  let isNew = false
  let isFastAndCheap = false

  if (product) {
    isOwnBrand = product.brand === "UDOBÄR"
    isNew = product.isNew ?? false
    //todo - correct implementation
    isFastAndCheap = product.category === "Schnell & Preiswert"
  }
  return { isOwnBrand, isNew, isFastAndCheap }
}

function CardDiscountBadge(props: { className?: string | undefined; variant: string | undefined; label: string }) {
  return (
    <div
      className={cn(
        "flex h-full items-center justify-center rounded-sm bg-primary text-primary-foreground",
        props.className
      )}
    >
      <p
        className={cn("p-1.5 text-xs font-bold", {
          "text-xs p-1": props.variant === "landscape",
        })}
      >
        {props.label && props.label !== "" && <>{props.label}</>}
      </p>
    </div>
  )
}

export default function ProductCardDiscount(props: {
  product: ReducedProductData
  prices: ProductPriceContainerCtnrROV1 | undefined
  variant?: string
  isB2BCustomer: boolean
  legacyPrices?: boolean
  quantity?: number
}) {
  const t = useTranslations("storefront")
  //const cardPrices: CardPrices = determineCardPrices(props.isB2BCustomer, props.prices)

  const cardPrices = useMemo(() => {
    if (props.legacyPrices) {
      //if we have legacy prices then ecoTax is already included
      return determineCardPrices(props.isB2BCustomer, props.prices, 0, props.quantity)
    } else {
      return determineCardPrices(props.isB2BCustomer, props.prices, props.product?.ecoTax, props.quantity)
    }
  }, [props.isB2BCustomer, props.prices, props.product?.ecoTax, props.quantity, props.legacyPrices])

  const { isOwnBrand, isNew, isFastAndCheap } = determineSpecialFlags(props.product)
  return (
    <div
      className={cn("absolute left-4 top-4 z-50 h-8 bg-transparent", {
        "left-2 top-2 h-8": props.variant === "landscape",
      })}
    >
      <div className={"flex flex-col items-start gap-0.5"}>
        {cardPrices && cardPrices.discountPercent > 0 ? (
          <CardDiscountBadge variant={props.variant} label={cardPrices.discountPercentFormatted} />
        ) : (
          <div></div>
        )}
        {SHOW_ADDITIONAL_BADGES && (
          <>
            {isNew && <CardDiscountBadge className={"bg-green-700"} variant={props.variant} label={t("product.new")} />}
            {isOwnBrand && (
              <CardDiscountBadge className={"bg-yellow-500"} variant={props.variant} label={t("product.own_brand")} />
            )}
            {isFastAndCheap && (
              <CardDiscountBadge className={"bg-red-600"} variant={props.variant} label={t("product.promotion")} />
            )}
          </>
        )}
      </div>
    </div>
  )
}

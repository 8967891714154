import { ReactNode } from "react"
import Link from "next/link"
import { StoryblokComponent } from "@storyblok/react/rsc"
import {
  MARK_CODE,
  MARK_LINK,
  NODE_CODEBLOCK,
  NODE_HEADING,
  NODE_LI,
  NODE_OL,
  NODE_PARAGRAPH,
  NODE_QUOTE,
  NODE_UL,
  render,
} from "storyblok-rich-text-react-renderer"

import { cn } from "@/lib/utils"

export function getRenderedRichText(richtext) {
  return render(richtext, {
    defaultBlokResolver: (name, props) => {
      props.component = name
      return <StoryblokComponent blok={props} />
    },
    nodeResolvers: {
      [NODE_HEADING]: (children, props) => {
        switch (props.level) {
          case 1:
            return <h1 className="mb-4 text-3xl font-bold md:text-5xl">{children}</h1>
          case 2:
            return <h2 className="mb-3 text-2xl font-semibold md:text-4xl">{children}</h2>
          case 3:
            return <h3 className="mb-2 text-xl font-semibold md:text-3xl">{children}</h3>
          case 4:
            return <h4 className="mb-2 text-2xl font-medium">{children}</h4>
          case 5:
            return <h5 className="mb-1 text-xl font-medium">{children}</h5>
          case 6:
            return <h6 className="mb-1 text-lg font-medium">{children}</h6>
          default:
            return <p>{children}</p> // In case level is not 1-6, return a default p
        }
      },
      [NODE_CODEBLOCK]: (children, props) => {
        return (
          <pre className="rounded border border-gray-600 bg-gray-200 px-4 py-3">
            <code className={cn(props.class)}>{children}</code>
          </pre>
        )
      },
      [NODE_PARAGRAPH]: (children) => {
        return <p className="3xl:text-l">{children}</p>
      },
      [NODE_OL]: (children) => {
        return <ol className="ml-4 list-inside list-decimal">{children}</ol>
      },
      [NODE_UL]: (children) => {
        return <ul className="ml-4 list-inside list-disc">{children}</ul>
      },
      [NODE_LI]: (children: ReactNode[]) => {
        const unwrappedChildren = children.map((child) => {
          if (child && typeof child === "object" && "props" in child) {
            // If the child is a React component with props
            return child.props.children
          } else {
            // If the child is just a text node
            return child
          }
        })
        return <li className="">{unwrappedChildren}</li>
      },
      [NODE_QUOTE]: (children) => {
        return <blockquote className="my-3 border-s-4 border-gray-300 pl-4 italic">{children}</blockquote>
      },
    },
    markResolvers: {
      [MARK_CODE]: (children) => {
        return <code className="rounded border border-gray-600 bg-gray-200 px-1 py-0.5">{children}</code>
      },
      [MARK_LINK]: (children, props) => {
        const { linktype, href, target } = props

        let linkHref: string = href ?? ""

        const linkClasses = "text-gray-600 underline decoration-gray-600 hover:text-primary"

        if (linktype === "email") {
          // Email links: add `mailto:` scheme and map to <a>
          return (
            <a className={linkClasses} href={`mailto:${linkHref}`}>
              {children}
            </a>
          )
        }
        if (linktype === "url" || href?.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <a className={linkClasses} href={linkHref} target={target}>
              {children}
            </a>
          )
        }
        // Internal links: map to <Link>
        return (
          <Link prefetch={false} className={linkClasses} href={linkHref}>
            {children}
          </Link>
        )
      },
    },
  })
}

//actions that can be called from client but run on server!
"use server"

import { headers } from "next/headers"

import { getAccessTokenFromNextHeader } from "@/lib/intershop/access-token-helper"
import { get } from "@/lib/intershop/api-client"
import { BasketData, BasketItems } from "@/lib/intershop/interfaces/basket/basket"
import { logger } from "@/lib/logger"
import {
  addArticlesToCurrentBasket,
  computeEcoTax,
  fetchCurrentOrCreateBasket,
  mergeBasket,
  updateBasketItemQuantity,
} from "@/lib/storefront/basket/basket-service"
import { ExtendedProductPriceContainer, ReducedProductData } from "@/lib/storefront/product/product"
import { fetchPricesForProductLegacy, fetchProduct } from "@/lib/storefront/product/product-service"
import { RecoData } from "@/lib/storefront/reco/reco"
import { getRecommendationsForProduct } from "@/lib/storefront/reco/reco-service"
import { getSystemConfigValue } from "@/lib/system-config-loader"

export async function addArticleToCurrentBasketAction(article: string, quantity: number): Promise<BasketData> {
  logger.debug(`addArticleToCurrentBasketAction(${article}, ${quantity})`)

  await addArticlesToCurrentBasket(article, quantity)
  const currentBasketData: BasketData = await fetchCurrentOrCreateBasket()

  await computeEcoTax(currentBasketData)

  logger.logObject(
    "debug",
    `addArticleToCurrentBasketAction(${article}, ${quantity}): currentBasketData...lineItems`,
    currentBasketData.included?.lineItems,
    { depth: 4 }
  )
  return currentBasketData
}

export async function updateBasketItemQuantityAction(article: string, quantity: number): Promise<BasketData> {
  const updatedBasket = await updateBasketItemQuantity(article, quantity)

  await computeEcoTax(updatedBasket)

  return updatedBasket
}

export async function determineCrosssellingProductsAction(article: string): Promise<RecoData[]> {
  let result: RecoData[] = []

  const load_crossselling_products: string = getSystemConfigValue("load_crossselling_products")
  if (load_crossselling_products === "true") {
    result = await getRecommendationsForProduct(article)
  }
  return result
}

export async function getBasketItems(id: string): Promise<BasketItems | undefined> {
  return get<BasketItems>(`baskets/${id}/items`)
}

export async function mergeCurrentBasket(
  sourceBasketId: string | undefined,
  sourceApiToken: string
): Promise<BasketData> {
  const loggedInBasket: BasketData = await fetchCurrentOrCreateBasket()

  let mergedBasket: BasketData | undefined

  if (sourceBasketId) {
    mergedBasket = await mergeBasket(sourceBasketId, sourceApiToken, loggedInBasket.data.id)
  } else {
    mergedBasket = loggedInBasket
  }
  await computeEcoTax(mergedBasket)
  return mergedBasket
}

export async function getApiToken(): Promise<string> {
  return await getAccessTokenFromNextHeader()
}

export async function fetchProductAction(article: string): Promise<ReducedProductData | undefined> {
  return await fetchProduct(article)
}

export async function fetchExtendedPricesAction(article: string): Promise<ExtendedProductPriceContainer | undefined> {
  return await fetchPricesForProductLegacy(article, headers())
}

import Cookies from "js-cookie"
import { create } from "zustand"

interface UbPdpState {
  selectedFilterOptions: Record<string, string | undefined>
  useClientSelectedFilterOptions: boolean
  filterClicked: boolean
  variantSortAsc: boolean
  setFilterClicked: (filterClicked: boolean) => void
  setVariantSortAsc: (variantSortAsc: boolean) => void

  setUseClientSelectedFilterOptions: (useClientSelectedFilterOptions: boolean) => void

  selectOptionForAttribute: (id: string, value: string) => void
  setSelectedFilterOptions: (selectedFilterOptions: Record<string, string | undefined>) => void

  buyboxQuantity: number
  setBuyboxQuantity: (quantity: number) => void
}

export const useUbPdpState = create<UbPdpState>((set) => ({
  selectedFilterOptions: Cookies.get("selectedFilterOptions") ?? {},
  setSelectedFilterOptions: (selectedFilterOptions: Record<string, string | undefined>) =>
    set(() => ({ selectedFilterOptions: selectedFilterOptions })),

  useClientSelectedFilterOptions: false,
  filterClicked: true,
  variantSortAsc: true,

  setVariantSortAsc: (variantSortAsc: boolean) => set(() => ({ variantSortAsc: variantSortAsc })),

  setFilterClicked: (filterClicked: boolean) => set(() => ({ filterClicked: filterClicked })),

  setUseClientSelectedFilterOptions: (useClientSelectedFilterOptions: boolean) =>
    set(() => ({ useClientSelectedFilterOptions: useClientSelectedFilterOptions })),

  selectOptionForAttribute: (id: string, value: string) =>
    set((state) => ({
      ...state,
      selectedFilterOptions: { ...state.selectedFilterOptions, [id]: value },
      useClientSelectedFilterOptions: true,
    })),

  buyboxQuantity: 1,
  setBuyboxQuantity: (quantity: number) => set(() => ({ buyboxQuantity: quantity })),
}))

import {AccountContext} from "@/lib/storefront/account/account-service";
import {Checkbox, Input} from "@/components/ui/components";

interface AccountSignUpLegalProps {
  accountContext: AccountContext
}


export default function AccountSignUpLegal(props: AccountSignUpLegalProps) {
  return (

    <div>



      <div className={"flex flex-col gap-2"}>

        <p>Diese Seite ist durch reCAPTCHA geschützt und es gelten die Google Datenschutzbestimmungen und Nutzungsbedingungen.</p>
        <Checkbox />
        <Checkbox />


      </div>
    </div>
  );
}
import Link from "next/link"
import { useTranslations } from "next-intl"

import { Button } from "@/components/ui/components"

import { AddToBaskedWithQuantityInput, AddToBaskedWithQuantityInputProps } from "./AddToBasketWithQuantityInput"

interface AddToBasketWithQuantityInputOptionalProps extends AddToBaskedWithQuantityInputProps {
  showPrice: boolean
  needsQuotation: boolean
  priceRequestHref: string
}

export function AddToBasketWithQuantityInputOptional({
  showPrice,
  needsQuotation,
  priceRequestHref,
  prices,
  ...restProps
}: AddToBasketWithQuantityInputOptionalProps) {
  const t = useTranslations("storefront")

  return (
    <>
      {showPrice && prices && <AddToBaskedWithQuantityInput prices={prices} {...restProps} />}

      {!(showPrice && prices) && needsQuotation && (
        <Button className={""} aria-label={t("product.request_price")} size={"productCard"}>
          <Link prefetch={false} href={priceRequestHref}>
            {t("product.request_price")}
          </Link>
        </Button>
      )}
    </>
  )
}

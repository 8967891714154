"use client"
import {AccountContext} from "@/lib/storefront/account/account-service"
import React from "react";
import {Tabs, TabsContent, TabsList, TabsTrigger} from "@/components/ui/components";
import AccountSignUpB2B from "@/components/account/signup/AccountSignUpB2B";
import AccountSignUpB2C from "@/components/account/signup/AccountSignUpB2C";

interface AccountSignUpToggleProps {
  accountContext: AccountContext
}

export default function AccountSignUpToggle(props: AccountSignUpToggleProps) {


  return (

    <section className={"relative block bg-cover "}>
      <div className={"mx-auto w-full max-w-7xl justify-center"}>
        <div className={"flex w-full flex-col items-start justify-start gap-2 pt-4"}>
          <div className={"w-full grow bg-ub-background"}>Sign Up</div>


          <Tabs defaultValue="tab-b2b">

            <TabsList className="mb-3 gap-1 bg-transparent">

              <TabsTrigger
                value="tab-b2b"
                className="rounded-full data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-none"
              >

                Geschäftskunde
              </TabsTrigger>
              <TabsTrigger
                value="tab-b2c"
                className="rounded-full data-[state=active]:bg-primary data-[state=active]:text-primary-foreground data-[state=active]:shadow-none"
              >

                Privatkunde
              </TabsTrigger>
            </TabsList>

            <TabsContent value="tab-b2b">
              <AccountSignUpB2B accountContext={props.accountContext}/>
            </TabsContent>
            <TabsContent value="tab-b2c">
              <AccountSignUpB2C accountContext={props.accountContext}/>
            </TabsContent>

          </Tabs>


        </div>
      </div>
    </section>

  )
}

import {AccountContext} from "@/lib/storefront/account/account-service";
import {Input} from "@/components/ui/components";

interface AccountSignUpB2CContactProps {
  accountContext: AccountContext
}


export default function AccountSignUpB2CContact(props: AccountSignUpB2CContactProps) {
  return (

    <div>

      <div className={"flex flex-col items-start gap-2"}>

        <h2>Kontaktangaben</h2>
        <p>*Erforderliches Feld</p>

      </div>

      <div className={"grid grid-cols-1 gap-2 md:grid-cols-2"}>

        <div className={"flex flex-col gap-2"}>
          <label>Anrede</label>
          <Input className={""}/>
        </div>

        <div>
          <label>Titel</label>
          <Input className={""}/>
        </div>

        <div>
          <label>Vorname</label>
          <Input className={""}/>
        </div>

        <div>
          <label>Nachname</label>
          <Input className={""}/>
        </div>

        <div>

          <label>Geburtsdatum</label>
          <div className={"flex flex-row gap-2"}>
            <Input className={""}/>
            <Input className={""}/>
            <Input className={""}/>
          </div>
        </div>

        <div>
          <label>Telefon</label>
          <Input className={""}/>
        </div>


        <div>
          <label>Fax</label>
          <Input className={""}/>
        </div>

      </div>
    </div>
  );
}
import {
  DEFAULT_SEARCH_RESULT_PAGE_SIZES,
  DEFAULT_SEARCH_RESULT_SORT_ORDER,
  SEARCH_PARAMS_PAGE,
  SEARCH_PARAMS_PAGE_SIZE,
  SEARCH_PARAMS_SORT_ORDER,
} from "@/constants/ui"

import { SortOrder } from "@/lib/factfinder/factfinder-backend"

export interface PaginationInfo {
  start: number
  end: number
}

export const getPaginationInfo = (
  interval: number,
  page: number,
  lastPage: number,
  visibleCount: number
): PaginationInfo[] => {
  const chunksCount = Math.ceil(lastPage / interval)
  const chunks = Array.from({ length: chunksCount }, (_v, chunk) => {
    const rest = Math.ceil(lastPage - chunk * interval)
    const size = rest >= interval ? interval : rest
    return Array.from({ length: size }, (_v, i) => i + chunk * interval + 1)
  })
  const chunkIndexForPage = chunks.findIndex((chunk) => chunk.includes(page))

  const previousChunks = chunks.slice(0, chunkIndexForPage).map((chunk) => {
    return {
      start: chunk[0],
      end: chunk.slice(-1)[0],
    } as PaginationInfo
  })
  const currentChunk = chunks[chunkIndexForPage]?.map((chunk) => {
    return {
      start: chunk,
      end: chunk,
    } as PaginationInfo
  })
  const nextChunks = chunks.slice(chunkIndexForPage + 1).map((chunk) => {
    return {
      start: chunk[0],
      end: chunk.slice(-1)[0],
    } as PaginationInfo
  })

  const remainingCount = visibleCount - currentChunk?.length
  const fullResultList = [...previousChunks, ...(currentChunk ?? []), ...nextChunks]
  const indexOfCurrent = fullResultList.findIndex((result) => result.start === currentChunk[0].start)
  const countPrev =
    nextChunks.length >= Math.floor(remainingCount / 2)
      ? Math.min(indexOfCurrent, Math.floor(remainingCount / 2))
      : Math.min(remainingCount - nextChunks.length, previousChunks.length)
  const countNext = Math.min(visibleCount - countPrev - currentChunk?.length, nextChunks.length)

  return [
    ...previousChunks.slice(-countPrev),
    ...(currentChunk ?? []),
    ...nextChunks.slice(0, countNext),
  ] as PaginationInfo[]
}

export interface PaginationSerachParams {
  page: number
  pageSize: number
  sortOrder: SortOrder
}

export const getPaginationSearchParams = (searchParams?: URLSearchParams): PaginationSerachParams => {
  const page = Number(searchParams?.get(SEARCH_PARAMS_PAGE) ?? 1)
  const pageSize = Number(searchParams?.get(SEARCH_PARAMS_PAGE_SIZE) ?? DEFAULT_SEARCH_RESULT_PAGE_SIZES[0])
  const sortOrder: SortOrder = (searchParams?.get(SEARCH_PARAMS_SORT_ORDER) ??
    DEFAULT_SEARCH_RESULT_SORT_ORDER[0]) as SortOrder

  return {
    page: page,
    pageSize: pageSize,
    sortOrder: sortOrder,
  }
}

export function paginateArray(array: any[], pageNumber: number, pageSize: number) {
  const startIndex = (pageNumber - 1) * pageSize
  return array.slice(startIndex, startIndex + pageSize)
}

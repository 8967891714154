import { ReducedProductData } from "@/lib/storefront/product/product"

export function calculateAvailabilityText(productData: ReducedProductData | undefined, t: any): string {
  const min = productData?.readyForShipmentMin
  const max = productData?.readyForShipmentMax

  if (!min || !max) {
    return ""
  }

  if (min <= 2) {
    return t("product.shipment_within48")
  } else if (min <= 3) {
    return t("product.shipment_days", { shipment: "2-3" })
  } else if (min <= 5) {
    return t("product.shipment_days", { shipment: "3-5" })
  } else if (min <= 7) {
    return t("product.shipment_days", { shipment: "5-7" })
  } else if (min <= 10) {
    return t("product.shipment_days", { shipment: "7-10" })
  } else if (min <= 15) {
    return t("product.shipment_days", { shipment: "10-15" })
  } else if (min <= 21) {
    return t("product.shipment_weeks", { shipment: "4" })
  } else if (min <= 25) {
    return t("product.shipment_weeks", { shipment: "4-5" })
  } else if (min === max) {
    return t("product.shipment_later")
  } else {
    return t("product.shipment_weeks", { shipment: min / 7 + "-" + max / 7 })
  }
}

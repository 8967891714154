"use client"

import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { CardPrices } from "@/lib/price-utils"
import { ReducedProductData } from "@/lib/storefront/product/product"

export default function ProductCardPrice(props: {
  productData: ReducedProductData
  cardPrices: CardPrices
  showEcoTaxFlag: boolean | undefined
  isB2BCustomer: boolean
  showPrice: boolean
}) {
  const t = useTranslations("storefront")

  const cardPrices = props.cardPrices

  const ecoTax: number | undefined = props.productData?.ecoTax
  const ecoTaxFormatted = formatPrice(ecoTax, props.productData.currency)

  const showEcoTax = !!props.showEcoTaxFlag && getCountry() === "fr" && !!ecoTax && ecoTax > 0

  const packagingUnitInfo = props.productData?.packagingUnitInfo ?? ""
  const pricePerUnitText = props.productData?.pricePerUnitText ?? ""
  const savingsPercent = props.productData?.savingsPercent ?? 0

  return (
    <div className={"flex flex-col items-end justify-end"}>
      {props.showPrice ? (
        <>
          {/*
        <p className="text-xs text-gray-400 text-nowrap">Eco Contribution: 2,34€</p>
*/}

          {showEcoTax && (
            <p className="text-nowrap text-xs text-gray2">{t("product.eco_tax", { ecoTax: ecoTaxFormatted })}</p>
          )}

          {pricePerUnitText !== "" && (
            <p className="text-nowrap text-xs text-gray2" dangerouslySetInnerHTML={{ __html: pricePerUnitText }} />
          )}
          {packagingUnitInfo !== "" && (
            <p className="text-nowrap text-xs text-gray2" dangerouslySetInnerHTML={{ __html: packagingUnitInfo }} />
          )}
          {/*     <div className="flex flex-col items-end justify-end gap-0.5">
            <p className="text-nowrap text-xs text-gray-500">
              {isB2BCustomer ? t("product.price_net") : t("product.price_gross")}
            </p>
          </div>*/}

          {cardPrices.listPriceFormatted !== cardPrices.salePriceFormatted || savingsPercent > 0 ? (
            props.isB2BCustomer ? (
              <div className="flex flex-row items-baseline justify-end gap-1">
                {!cardPrices.isSinglePrice && (
                  <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
                )}
                {cardPrices.listPriceFormatted !== cardPrices.salePriceFormatted && (
                  <p className="text-sm font-bold text-gray-500 line-through">{cardPrices.listPriceFormatted}</p>
                )}
              </div>
            ) : (
              <p className={"text-nowrap text-xs font-bold text-primary"}>{t("basket.add_dialog.special_offer")}</p>
            )
          ) : (
            props.isB2BCustomer && (
              <div className="flex flex-row items-baseline justify-end gap-1">
                <p className="text-sm font-bold text-transparent line-through">-</p>
              </div>
            )
          )}

          <div className="flex flex-row items-baseline justify-end gap-1">
            {!cardPrices.isSinglePrice && (
              <p className="text-nowrap text-sm text-gray-500">{t("product.price_from")}</p>
            )}
            <p className="text-xl font-bold text-primary">
              {cardPrices.salePriceFormatted}
              {/*{isB2BCustomer ? "*" : ""}*/}
            </p>
          </div>
        </>
      ) : (
        <>
          {" "}
          <p className={"text-nowrap text-base font-bold text-primary"}>{t("product.ask_price")}</p>
        </>
      )}
      {/*
      <textarea className={"hidden"} defaultValue={JSON.stringify(cardPrices)}/>
*/}
    </div>
  )
}

"use client"

import React, { useCallback, useEffect, useMemo, useState } from "react"
import Link from "next/link"
import { updateBasketItemQuantityAction } from "@/actions/basketActions"
import { useUbStorefrontState } from "@/store/ubStorefrontState"
import {
  CircleCheckIcon,
  CircleMinusIcon,
  CirclePlusIcon,
  LoaderCircleIcon,
  ShoppingCart,
  XCircleIcon,
} from "lucide-react"
import { useTranslations } from "next-intl"

import { formatPrice } from "@/lib/helper"
import { DiscountType } from "@/lib/intershop/interfaces/basket/basket"
import { getCountry } from "@/lib/link-utils/get-sb-locale"
import { CardPrices, determineCardPrices } from "@/lib/price-utils"
import { findLineItemForSku } from "@/lib/storefront/basket/basketHelper"
import {
  Button,
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/components"
import { cn } from "@/components/ui/helpers/utils"
import ProductCard from "@/components/product/ProductCard"
import ImageWithFallback from "@/components/storyblok/common/ImageWithFallback"
import { UbAddToBasketFormStoryblok } from "@/components/storyblok/component-types"

const SHOW_SCALE_PRICE_LOGIC = true

interface AddToBasketDialogClientProps {
  blok: UbAddToBasketFormStoryblok
  hideAddToBasket: boolean
  priceRequestLink: string
}

export default function AddToBasketDialogClient({
  blok,
  hideAddToBasket,
  priceRequestLink,
}: AddToBasketDialogClientProps) {
  const { addToBasketDialogVisible, setAddToBasketDialogVisible, isB2BCustomer } = useUbStorefrontState()
  const {
    productDataAddedToBasket,
    productDataAddedToBasketPrices,
    basketData,
    setBasketData,
    crosssellingProductDataAddedToBasket,
    setCrosssellingProductDataAddedToBasket,
  } = useUbStorefrontState()
  const t = useTranslations("storefront")

  /*  console.log("AddToBasketDialogClient: basketData=")
  console.dir(basketData)*/

  const [quantity, setQuantity] = useState<number>(1)
  const [initialQuantity, setInitialQuantity] = useState<number>(1)
  const [changingQuantity, setChangingQuantity] = useState<boolean>(false)

  const cardPrices: CardPrices = useMemo(() => {
    return determineCardPrices(isB2BCustomer, productDataAddedToBasketPrices)
  }, [isB2BCustomer, productDataAddedToBasketPrices])

  const existingLineItem = useMemo(() => {
    return basketData ? findLineItemForSku(basketData, productDataAddedToBasket?.id) : null
  }, [basketData, productDataAddedToBasket])

  const totalItemPrice = useMemo(() => {
    if (isB2BCustomer) {
      return formatPrice(existingLineItem?.pricing.price?.net.value, existingLineItem?.pricing.price?.net.currency)
    } else {
      return formatPrice(existingLineItem?.pricing.price?.gross.value, existingLineItem?.pricing.price?.gross.currency)
    }
  }, [isB2BCustomer, existingLineItem])

  const hasDiscount = useMemo(() => {
    return cardPrices.discountPercent > 0
  }, [cardPrices])

  function closeDialog() {
    setAddToBasketDialogVisible(false)
    setCrosssellingProductDataAddedToBasket([])
  }

  const ecoTaxFormatted = useMemo(() => {
    return formatPrice(productDataAddedToBasket?.ecoTax, productDataAddedToBasket?.currency)
  }, [productDataAddedToBasket])

  const hasEcoTax = useMemo(() => {
    return (
      getCountry() === "fr" && productDataAddedToBasket?.ecoTax !== undefined && productDataAddedToBasket?.ecoTax > 0.0
    )
  }, [productDataAddedToBasket])

  const normalAmountFormatted = useMemo(() => {
    return formatPrice(
      cardPrices.listPrice * (existingLineItem?.quantity?.value ?? 1),
      productDataAddedToBasket?.currency
    )
  }, [cardPrices, productDataAddedToBasket, existingLineItem])

  const discountAmountFormatted = useMemo(() => {
    return formatPrice(
      (cardPrices.listPrice - cardPrices.salePrice) * (existingLineItem?.quantity?.value ?? 1),
      productDataAddedToBasket?.currency
    )
  }, [cardPrices, productDataAddedToBasket, existingLineItem])

  const showHandlingFeeHint = useMemo(() => {
    return (
      getCountry() === "de" &&
      !isB2BCustomer &&
      existingLineItem?.pricing.price?.gross?.value !== undefined &&
      existingLineItem?.pricing.price?.gross?.value < 50.0
    )
  }, [isB2BCustomer, existingLineItem])

  const discountType = useMemo(() => {
    let discountAttributeValue: string | undefined
    let discountTypeString: DiscountType = "SPECIAL"
    if (existingLineItem) {
      discountAttributeValue = existingLineItem["attributes"]?.find(
        (attributeValue) => attributeValue.name === "UBPLIRebateRate"
      )?.value as string

      if (discountAttributeValue) {
        //todo check if other values also set GENERAL discount type
        if (discountAttributeValue.startsWith("DISCOUNT_TYPE_CUSTOMER_GENERAL")) {
          discountTypeString = "GENERAL"
        }
      }
    }

    return discountTypeString
  }, [existingLineItem])

  const hasScalePrice = useMemo(() => {
    const salePrices = productDataAddedToBasketPrices?.data?.[0]?.prices?.SalePrice

    if (salePrices && SHOW_SCALE_PRICE_LOGIC) {
      if (salePrices.length > 1) {
        const bestSalePrice = salePrices[salePrices.length - 1]

        const bestPriceQuantity = bestSalePrice?.minQuantity?.value ?? 0
        const lineItem = basketData ? findLineItemForSku(basketData, productDataAddedToBasket?.id) : null
        const lineItemQuantity = lineItem?.quantity.value ?? 0
        return bestPriceQuantity > lineItemQuantity
      }
    } else {
      return false
    }
  }, [productDataAddedToBasketPrices, basketData, productDataAddedToBasket])

  const formattedBestPrice = useMemo(() => {
    const salePrices = productDataAddedToBasketPrices?.data?.[0]?.prices?.SalePrice

    if (salePrices && SHOW_SCALE_PRICE_LOGIC) {
      const bestSalePrice = salePrices[salePrices.length - 1]

      if (isB2BCustomer) {
        return formatPrice(bestSalePrice?.net?.value, bestSalePrice?.net?.currency)
      } else {
        return formatPrice(bestSalePrice?.gross?.value, bestSalePrice?.gross?.currency)
      }
    } else {
      return false
    }
  }, [productDataAddedToBasketPrices, isB2BCustomer])

  const formattedPriceFromQuantity = useMemo(() => {
    const salePrices = productDataAddedToBasketPrices?.data?.[0]?.prices?.SalePrice

    if (salePrices && SHOW_SCALE_PRICE_LOGIC) {
      let currentSalePrice = salePrices[0]

      for (const salePrice of salePrices) {
        const minQuantity = salePrice?.minQuantity?.value ?? 1
        if (quantity >= minQuantity) {
          currentSalePrice = salePrice
        }
      }

      if (isB2BCustomer) {
        return formatPrice(currentSalePrice?.net?.value, currentSalePrice?.net?.currency)
      } else {
        return formatPrice(currentSalePrice?.gross?.value, currentSalePrice?.gross?.currency)
      }
    } else {
      return false
    }
  }, [productDataAddedToBasketPrices, isB2BCustomer, quantity])

  const bestPriceQuantity = useMemo(() => {
    const salePrices = productDataAddedToBasketPrices?.data?.[0]?.prices?.SalePrice

    if (salePrices && SHOW_SCALE_PRICE_LOGIC) {
      const bestSalePrice = salePrices[salePrices.length - 1]

      return bestSalePrice?.minQuantity?.value ?? 0
    } else {
      return false
    }
  }, [productDataAddedToBasketPrices])

  const formattedPackagingUnit = useMemo(() => {
    if (productDataAddedToBasket?.packingUnit) {
      return t("packaging_unit." + productDataAddedToBasket?.packingUnit) ===
        "packaging_unit." + productDataAddedToBasket?.packingUnit
        ? t("packaging_unit.PCE")
        : t("packaging_unit." + productDataAddedToBasket?.packingUnit)
    } else {
      return t("packaging_unit.PCE")
    }
  }, [productDataAddedToBasket, t])
  const [animateHeadline, setAnimateHeadline] = useState<string>("")

  function updateQuantity(newQuantity: number) {
    if (newQuantity >= initialQuantity) {
      setQuantity(newQuantity)
    }
  }

  useEffect(() => {
    const initialQuantity = existingLineItem?.quantity.value ?? 0
    setInitialQuantity(initialQuantity)
    setQuantity(initialQuantity)
  }, [existingLineItem])

  const escFunction = useCallback(
    (event) => {
      if (event.key === "Escape") {
        setAddToBasketDialogVisible(false)
        setCrosssellingProductDataAddedToBasket([])
      }
    },
    [setAddToBasketDialogVisible, setCrosssellingProductDataAddedToBasket]
  )

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [escFunction])

  function updateBasketItemQuantityHandler() {
    if (productDataAddedToBasket) {
      setChangingQuantity(true)
      updateBasketItemQuantityAction(productDataAddedToBasket?.id, quantity).then((basketData) => {
        setChangingQuantity(false)
        setBasketData(basketData)
        setAnimateHeadline("pulse")
        setTimeout(() => {
          setAnimateHeadline("")
        }, 5000)
      })
    }
  }

  return (
    <>
      {addToBasketDialogVisible && !productDataAddedToBasket && (
        <>
          <div className={"fixed inset-0 z-modal-level bg-black/90"} onClick={() => closeDialog()}></div>
          <div
            className={
              "fixed left-1/2 top-1/3 z-modal-level w-[95%] max-w-4xl -translate-x-1/2 -translate-y-1/3 bg-white md:w-4/5"
            }
          >
            <h1 className={"mr-2 p-5 text-xs font-bold sm:text-sm lg:text-base"}>
              {/* TODO */}
              Problem
            </h1>
          </div>
        </>
      )}
      {addToBasketDialogVisible && productDataAddedToBasket && (
        <>
          <div className={"fixed inset-0 z-modal-level bg-black/90"} onClick={() => closeDialog()}></div>
          <div
            className={
              "fixed left-1/2 top-1/3 z-modal-level w-[95%] max-w-4xl -translate-x-1/2 -translate-y-1/3 bg-white md:w-4/5"
            }
          >
            <div
              data-progress={changingQuantity ? "running" : ""}
              className={"flex flex-col gap-4 border-t-4 border-green-600 data-[progress=running]:border-yellow-600 "}
            >
              <div
                data-progress={changingQuantity ? "running" : ""}
                className={
                  "flex min-h-12 w-full items-center justify-between gap-4 bg-green-100 px-2 data-[progress=running]:bg-yellow-100"
                }
              >
                <div
                  data-animate={animateHeadline}
                  className={"flex justify-start gap-4 data-[animate=pulse]:animate-pulse"}
                >
                  {changingQuantity ? (
                    <>
                      <LoaderCircleIcon className={"size-6 animate-spin text-yellow-500"} />
                      <h1 className={"mr-2 text-xs font-bold sm:text-sm lg:text-base"}>...</h1>
                    </>
                  ) : (
                    <>
                      <CircleCheckIcon className={"size-6 text-green-500"} />
                      <h1 className={"mr-2 text-xs font-bold sm:text-sm lg:text-base"}>
                        {t("basket.product_added_successfully")}
                      </h1>
                    </>
                  )}
                </div>
                <button onClick={closeDialog}>
                  <XCircleIcon className={"size-6"} />
                </button>
              </div>

              <div className={"flex w-full flex-col justify-between gap-4 p-4 sm:flex-row"}>
                <div className={"relative flex w-full grow items-center justify-center gap-4 md:w-2/12"}>
                  <ImageWithFallback
                    unoptimized={true}
                    className="aspect-square max-h-40 overflow-hidden object-contain"
                    alt={productDataAddedToBasket.title}
                    src={productDataAddedToBasket.image ?? ""}
                    width={productDataAddedToBasket.image_width}
                    height={productDataAddedToBasket.image_height}
                  />
                  <div
                    className={
                      "center absolute -right-2 bottom-2 flex aspect-square h-6 items-center justify-center rounded-full border border-black bg-muted text-sm font-bold drop-shadow-md md:h-8 md:text-lg"
                    }
                  >
                    {existingLineItem?.quantity?.value ?? ""}
                  </div>
                </div>
                <div
                  className={
                    "flex w-full flex-col items-start justify-start overflow-hidden pl-0 text-sm sm:items-start md:w-7/12 md:pl-4"
                  }
                >
                  <h1 className={"text-sm font-bold tracking-tight md:text-base"}>{productDataAddedToBasket.title}</h1>

                  {!hasScalePrice ? (
                    <>
                      <p className={"font-bold text-muted-foreground"}>
                        {t("product.article_number")}: {"A" + productDataAddedToBasket.id}
                      </p>
                      <div className={"grid grid-cols-1 justify-start gap-x-4 gap-y-1 md:grid-cols-2"}>
                        {productDataAddedToBasket?.variableVariationAttributes?.map((variationAttribute) => (
                          <p key={variationAttribute.name} className={"font-bold text-muted-foreground"}>
                            {variationAttribute.name}: {variationAttribute.value}
                          </p>
                        ))}
                      </div>
                    </>
                  ) : (
                    <div className={"flex w-full flex-col "}>
                      <div className={"flex w-full flex-col justify-start md:flex-row lg:gap-4"}>
                        <p className={"text-sm font-bold text-primary lg:text-lg"}>
                          {t("basket.add_dialog.our_best_price")}: {formattedBestPrice}
                        </p>
                        <p className={"text-sm font-bold lg:text-lg"}>
                          {t("basket.add_dialog.your_price")}: {formattedPriceFromQuantity}
                        </p>
                      </div>

                      <p className={"text-xs text-muted-foreground lg:text-sm"}>
                        {t("basket.add_dialog.step_info", {
                          price_unit: formattedPackagingUnit,
                          quantity: bestPriceQuantity,
                        })}
                      </p>

                      {/*
                      <p>{JSON.stringify(cardPrices.scalePrices)}</p>
*/}
                    </div>
                  )}
                </div>

                <div className={"flex w-full flex-col items-start justify-between md:w-3/12 md:items-end"}>
                  {!hasScalePrice ? (
                    <div className={"flex w-full flex-col items-end justify-start gap-2"}>
                      <div className={"flex justify-between"}>
                        <p>{t("product.single_price")}:&nbsp;</p>
                        <p className="font-bold">{formattedPriceFromQuantity}</p>
                      </div>

                      {hasDiscount && (
                        <>
                          {isB2BCustomer ? (
                            <div className={"text-muted-background flex justify-between text-xs"}>
                              <p className={"text-nowrap"}> {t("basket.add_dialog.normal")}:&nbsp;</p>
                              <p className="text-right line-through">{normalAmountFormatted}</p>
                            </div>
                          ) : (
                            <p className={"text-nowrap text-xs font-bold text-primary"}>
                              {t("basket.add_dialog.special_offer")}
                            </p>
                          )}
                          {isB2BCustomer && (
                            <div className={"flex justify-between text-xs font-bold text-green-500"}>
                              {discountType === "SPECIAL" ? (
                                <p className={"text-nowrap"}>
                                  {" "}
                                  {t("basket.add_dialog.discount", {
                                    discountPercentFormatted: cardPrices.discountPercentFormatted,
                                  })}
                                  :&nbsp;
                                </p>
                              ) : (
                                <p className={"text-nowrap"}>
                                  {" "}
                                  {t("basket.add_dialog.discount_general", {
                                    discountPercentFormatted: cardPrices.discountPercentFormatted,
                                  })}
                                  :&nbsp;
                                </p>
                              )}
                              <p className="text-right">{<>{discountAmountFormatted}</>}</p>
                            </div>
                          )}
                        </>
                      )}

                      {hasEcoTax && (
                        <div className={"text-muted-background flex justify-between text-xs"}>
                          <p className={"text-nowrap"}>{t("header.shopping_basket.eco_tax")}:&nbsp;</p>
                          <p className="text-right">{<>{ecoTaxFormatted}</>}</p>
                        </div>
                      )}

                      <div className={"flex items-end justify-between"}>
                        <p>{t("product.total_price")}:&nbsp;</p>
                        <p className={"text-2xl font-bold"}>{totalItemPrice}</p>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className={"flex w-44 items-center justify-between"}>
                        <button
                          className={cn("rounded-full", {
                            "text-muted-foreground opacity-20": !(quantity > initialQuantity),
                          })}
                          onClick={() => updateQuantity(quantity - (productDataAddedToBasket?.stepOrderQuantity ?? 1))}
                        >
                          <CircleMinusIcon className={"size-7"} />
                        </button>
                        <p className={"text-lg font-bold"}>{quantity}</p>
                        <button
                          className={cn("rounded-full")}
                          onClick={() => updateQuantity(quantity + (productDataAddedToBasket?.stepOrderQuantity ?? 1))}
                        >
                          <CirclePlusIcon className={"size-7"} />
                        </button>

                        <Button
                          aria-label={t("product.add_to_basket")}
                          size={"productCardIcon"}
                          onClick={(e) => {
                            e.preventDefault()
                            updateBasketItemQuantityHandler()
                          }}
                        >
                          <ShoppingCart className={"size-4"} />
                        </Button>
                      </div>
                      <p className={"text-xs text-muted-foreground"}>
                        {t("basket.add_dialog.quantity_step")}: {productDataAddedToBasket.stepOrderQuantity}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <div className={"flex w-full justify-end px-4"}>
                {hasEcoTax ? (
                  <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>
                    {t("product.tax_hint_eco", { ecoTax: ecoTaxFormatted })}
                  </p>
                ) : (
                  <>
                    {showHandlingFeeHint ? (
                      <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>
                        {blok.body[0]?.handling_fee_hint ?? ""}
                      </p>
                    ) : (
                      <p className={"w-auto text-xs text-muted-foreground sm:text-sm"}>
                        {blok.body[0]?.tax_hint ?? ""}
                      </p>
                    )}
                  </>
                )}
              </div>

              <div className={"flex w-full flex-col justify-between gap-2 p-4 sm:flex-row"}>
                <Button onClick={closeDialog} size={"lg"} variant="outline">
                  {t("basket.add_dialog.shopping")}
                </Button>

                <Button asChild size={"lg"}>
                  <Link prefetch={false} href={blok?.body[0]?.basket_url?.cached_url ?? "#"}>
                    {t("basket.add_dialog.basket")}
                  </Link>
                </Button>
              </div>
              {crosssellingProductDataAddedToBasket.length > 0 && (
                <div className={"flex flex-col gap-4 p-2"}>
                  <h1 className={"mx-2 hidden text-sm font-bold sm:flex lg:text-xl"}>
                    {blok?.body[0]?.crossselling_title ?? ""}
                  </h1>
                  <div className={"hidden h-[30rem] w-full flex-row justify-center gap-2 p-1 sm:flex"}>
                    <Carousel
                      opts={{
                        align: "start",
                        loop: true,
                      }}
                      className="w-full px-4 @container/carousel"
                    >
                      <CarouselContent>
                        {crosssellingProductDataAddedToBasket?.slice(0, 8).map((recoData, index) => (
                          <CarouselItem
                            key={recoData.productData.id || index}
                            className="@carousel:basis-1 flex justify-center @lg/carousel:basis-1/2 @xl/carousel:basis-1/3"
                          >
                            <div className="relative flex w-full max-w-[21.5rem] flex-col overflow-hidden rounded border-2 border-primary bg-white 3xl:max-w-[25rem]">
                              <ProductCard
                                compact={true}
                                showEcoTaxFlag={false}
                                productData={recoData.productData || recoData.productData}
                                showDescription={false}
                                /*standardVariant={recoData.standardVariant}*/
                                prices={recoData.priceData}
                                isB2BCustomer={isB2BCustomer}
                                hideAddToBasket={hideAddToBasket}
                                priceRequestLink={priceRequestLink}
                              />
                            </div>
                          </CarouselItem>
                        ))}
                      </CarouselContent>
                      <CarouselPrevious
                        className={"border-2 border-primary bg-white text-primary"}
                        bannermode={false}
                        fullwidth={false}
                      />
                      <CarouselNext
                        className={"border-2 border-primary bg-white text-primary"}
                        bannermode={false}
                        fullwidth={false}
                      />
                    </Carousel>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

import {AccountContext} from "@/lib/storefront/account/account-service";
import {Input} from "@/components/ui/components";

interface AccountSignUpAddressProps {
  accountContext: AccountContext
}


export default function AccountSignUpAddress(props: AccountSignUpAddressProps) {
  return (

    <div>

      <div className={"flex flex-col items-start gap-2"}>

        <h2>Adresse</h2>
        <p>Um bei der Bestellung Zeit zu sparen, geben Sie unten Ihre Hauptadressen für Versand und Lieferung an. Die Informationen werden gespeichert, sodass Sie sie bei zukünftigen Bestellungen nicht mehr jedes Mal erneut eingeben müssen.

        </p>

        <p>*Erforderliches Feld</p>

      </div>

      <div className={"grid grid-cols-1 gap-2 md:grid-cols-2"}>

        <div className={"flex flex-col gap-2"}>
          <label>Land</label>
          <Input className={""}/>
        </div>

        <div>
          <label>Straße</label>
          <Input className={""}/>
        </div>

        <div>
          <label>Adresszusatz</label>
          <Input className={""}/>
          <p>mindestens 10 Zeichen | Kleinbuchstabe | Großbuchstabe | Sonderzeichen | eine Zahl</p>
        </div>

        <div>
          <label>PLZ</label>
          <Input className={""}/>
        </div>

      </div>
    </div>
  );
}